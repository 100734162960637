import moment from 'moment';
import { del, get, patch, post } from './httpRequest';

const calculateStartAmount = ({ amount, schedule, timesDue }) => {
  if (schedule === 'SPREAD') return Number(amount * timesDue);

  if (schedule === 'RECURRING') return Number(amount);

  return Number(amount);
};

export const fetchAdjustmentsApi = async () => {
  const response = await get(`/adjustments`);

  return response.data;
};

export const fetchAdjustmentApi = async (id) => {
  const response = await get(`/adjustments/${id}`);

  return response.data;
};

export const fetchAdjustmentLogApi = async (id) => {
  const response = await get(`/adjustments/${id}/logs`);

  return response.data;
};

export const createAdjustmentApi = async (data) => {
  const formatStartDate = moment(data.startDate, 'DD/MM/YYYY').toISOString(false);
  const startAmount = calculateStartAmount({ ...data });
  let interval;

  if (data.schedule !== 'ONE_OFF') {
    interval = 'WEEKLY';
  }

  const response = await post(`/adjustments`, {
    data: {
      ...data,
      amount: Number(data.amount),
      startDate: formatStartDate,
      startAmount,
      interval,
    },
  });

  return response.data;
};

export const patchAdjustmentApi = async (id, data) => {
  const formatStartDate = moment(data.startDate, 'DD/MM/YYYY').toISOString(false);
  const startAmount = calculateStartAmount({ ...data });
  let interval;

  if (data.schedule !== 'ONE_OFF') {
    interval = 'WEEKLY';
  }

  const response = await patch(`/adjustments/${id}`, {
    data: {
      ...data,
      amount: Number(data.amount),
      startDate: formatStartDate,
      startAmount,
      interval,
    },
  });

  return response.data;
};

export const deleteAdjustmentApi = async (id) => {
  const response = await del(`/adjustments/${id}`);

  return response.data;
};

export const deactivateAdjustmentApi = async (id) => {
  const response = await patch(`/adjustments/${id}/deactivate`);

  return response.data;
};
