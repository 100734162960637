import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Chip,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { getAdjustmentLogState, getAdjustmentState } from '../../../../store/adjustments/selectors';
import shouldLoad from '../../../../shared/utils/shouldLoad';
import { clearAdjustmentLog, fetchAdjustmentLog } from '../../../../store/adjustments';
import withVenue from '../../../../hoc/withVenue';

const useStyles = makeStyles((theme) => ({
  tableOverrides: {
    '& .MuiTableCell-head': {
      ...theme.customFonts.label,
      backgroundColor: theme.sessionsScorePalette.nonCompliantLightGrey,
    },
  },
  sectionTitle: {
    ...theme.customFonts.mediumBold,
  },
  activeChip: {
    backgroundColor: '#87EB80',
    ...theme.customFonts.xSmall,
    color: '#000',
  },
  inactiveChip: {
    backgroundColor: '#E0E0EB',
    ...theme.customFonts.xSmall,
    color: '#000',
  },
  formLabel: {
    ...theme.customFonts.small,
    color: '#000',
  },
  formDescription: {
    ...theme.customFonts.small,
    color: '#5A7296',
  },
  summaryAmount: {
    ...theme.customFonts.xLarge,
    color: '#000',
  },
  zeroResultsCell: {
    padding: theme.spacing(3),
    '& > p': {
      ...theme.customFonts.medium,
      textAlign: 'center',
    },
  },
}));

const AdjustmentLog = ({ adjustmentId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading: adjustmentLogLoading, data: adjustmentLog } = useSelector(getAdjustmentLogState);
  const { data: adjustment } = useSelector(getAdjustmentState);

  const { createdAt, createdBy, type, amount, timesDue, schedule, startDate, description, status } =
    adjustment || {};

  const formattedStartDate = moment(startDate).format('DD/MM/YYYY');
  const endDate = formattedStartDate
    ? moment(startDate).add(timesDue, 'weeks').format('DD/MM/YYYY')
    : '';

  useEffect(() => {
    if (shouldLoad(getAdjustmentLogState) && adjustmentId) {
      dispatch(fetchAdjustmentLog(adjustmentId));
    }
  }, [dispatch, adjustmentId]);

  const getStatusChip = ({ fixedStatus } = {}) => {
    const chipStatus = fixedStatus || status;
    switch (chipStatus) {
      case 'ACTIVE':
        return <Chip label="Active" size="small" className={classes.activeChip} />;
      case 'COMPLETED':
        return <Chip label="Completed" size="small" className={classes.activeChip} />;
      case 'DEACTIVATED':
        return <Chip label="Deactivated" size="small" className={classes.inactiveChip} />;
      case 'PENDING':
        return <Chip label="Active" size="small" className={classes.activeChip} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h2" className={classes.sectionTitle}>
              {adjustment.type === 'CHARGE' ? 'Charges' : 'Payments'}
            </Typography>
            <TableContainer sx={{ mt: 2 }}>
              <Table className={classes.tableOverrides} size="small">
                <TableHead className={classes.columnTitles}>
                  <TableRow>
                    <TableCell>WEEK ENDIING</TableCell>
                    <TableCell>STATUS</TableCell>
                    <TableCell align="right">AMOUNT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adjustmentLog && adjustmentLog.length > 0 ? (
                    adjustmentLog?.map((log) => (
                      <TableRow key={log.logId}>
                        <TableCell>{moment(log.payoutDay).format('Do MMM YY')}</TableCell>
                        <TableCell>{getStatusChip({ fixedStatus: 'COMPLETED' })}</TableCell>
                        <TableCell align="right">£{log.amount.toFixed(2)}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableCell colSpan={3} className={classes.zeroResultsCell}>
                      {adjustmentLogLoading ? (
                        <Typography variant="body1">Loading adjustments...</Typography>
                      ) : (
                        <Typography variant="body1">No payments</Typography>
                      )}
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper sx={{ p: 2, mb: 2 }}>
            {getStatusChip()}
            <Typography variant="body1" sx={{ mt: 1 }}>
              Created {moment(createdAt).format('Do MMM YYYY')}
            </Typography>
            <Typography variant="body1">By: {createdBy}</Typography>
          </Paper>

          <Paper sx={{ p: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Summary
            </Typography>
            <Typography variant="body2" className={classes.formLabel} sx={{ mt: 2 }}>
              {type === 'CHARGE' ? 'Charge this venue:' : 'Pay this venue:'}
            </Typography>
            <Typography variant="body1" className={classes.summaryAmount}>
              {amount ? `£${amount} ${schedule === 'SPREAD' ? ' each week' : ''}` : '£0.00'}
            </Typography>
            {timesDue && schedule === 'SPREAD' && (
              <Typography variant="body1" className={classes.formLabel} sx={{ mb: 1 }}>
                for {timesDue} weeks
              </Typography>
            )}
            <Typography variant="body1" className={classes.formLabel}>
              Starting: {formattedStartDate}
            </Typography>
            {schedule !== 'ONE_OFF' && (
              <Typography variant="body1" className={classes.formLabel}>
                Ending: {timesDue === -1 ? 'Never' : endDate}
              </Typography>
            )}
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1" className={classes.formLabel}>
              Description
            </Typography>
            <TextField
              name="description"
              minRows="5"
              value={description}
              fullWidth
              disabled
              multiline
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withVenue(AdjustmentLog, null, [clearAdjustmentLog]);
