import React from 'react';
import {
  Box,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  Typography,
  RadioGroup,
  Divider,
  InputAdornment,
  Checkbox,
  Chip,
} from '@mui/material';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { CurrencyPound } from '@mui/icons-material';
import UniversalSave from '../../../../components/UniversalSave';
import { ADJUSTMENT_TYPES, SCHEDULE_TYPES } from '../../../../shared/constants/venueAdjustments';

const AdjustmentSchema = Yup.object().shape({
  option: Yup.string().required('Select an adjustment type'),
  name: Yup.string().required('Name is required'),
  type: Yup.string().oneOf(['CHARGE', 'PAYMENT']).required(),
  schedule: Yup.string().oneOf(['ONE_OFF', 'SPREAD', 'RECURRING']).required('Select a schedule'),
  description: Yup.string(),
  startDate: Yup.string().required('Start date is required'),
  amount: Yup.number('Must be a valid number').required('Amount is required'),
  vatIncluded: Yup.boolean().required(),
  timesDue: Yup.number().required(),
});

const useStyles = makeStyles((theme) => ({
  formLabel: {
    ...theme.customFonts.small,
    color: '#000',
  },
  formDescription: {
    ...theme.customFonts.small,
    color: '#5A7296',
  },
  summaryAmount: {
    ...theme.customFonts.xLarge,
    color: '#000',
  },
  datePicker: {
    '&.MuiInputBase-input.MuiOutlinedInput-input': {
      padding: '8.5px 14px',
    },
  },
}));

const dateIsMonday = (date) => date.toDate().getDay() === 1;

const getSummaryAmount = ({ amount, applyVat, mode }) => {
  if (applyVat && mode !== 'CREATE') {
    const numericAmount = Number(amount);
    const vatAmount = Number((numericAmount * 0.2).toFixed(2));
    const totalAmount = Number((numericAmount + vatAmount).toFixed(2));
    return totalAmount.toFixed(2);
  }

  return Number(amount).toFixed(2);
};

const AdjustmentForm = ({ initialValues, handleSubmit, adjustmentCreated, mode = 'CREATE' }) => {
  const classes = useStyles();

  return (
    <Formik validationSchema={AdjustmentSchema} initialValues={initialValues}>
      {({ values, errors, isValid, resetForm, dirty, setFieldValue }) => {
        const formattedStartDate = moment(values.startDate).format('DD/MM/YYYY');
        const endDate = values.startDate
          ? moment(values.startDate).clone().add(values.timesDue, 'weeks').format('DD/MM/YYYY')
          : '';

        return (
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Paper>
                  <>
                    <UniversalSave
                      dirty={dirty}
                      errors={errors || !isValid}
                      onSave={() => {
                        handleSubmit({
                          ...values,
                          startDate: formattedStartDate,
                          amount: getSummaryAmount({
                            amount: values.amount,
                            applyVat: values.vatIncluded,
                            mode,
                          }),
                        });
                      }}
                      onDiscard={resetForm}
                    />
                    <Form>
                      <CardContent>
                        <FormControl fullWidth>
                          <FormLabel className={classes.formLabel}>Adjustment type</FormLabel>
                          <Field
                            component={TextField}
                            select
                            name="option"
                            value={values.option}
                            size="small"
                            required
                            helperText={errors.option}
                            error={!!errors.option}
                            onChange={(e) => {
                              setFieldValue('option', e.target.value);
                              setFieldValue(
                                'name',
                                ADJUSTMENT_TYPES.find((type) => type.value === e.target.value)
                                  .label,
                              );
                            }}
                          >
                            {ADJUSTMENT_TYPES.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <FormLabel className={classes.formLabel}>Name</FormLabel>
                          <FormLabel className={classes.formDescription}>Client facing</FormLabel>
                          <Field component={TextField} name="name" size="small" required />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <FormLabel className={classes.formLabel}>Charge or payment?</FormLabel>
                          <Field name="type">
                            {({ field }) => (
                              <RadioGroup
                                {...field}
                                onChange={(e) => setFieldValue('type', e.target.value)}
                              >
                                <FormControlLabel
                                  value="CHARGE"
                                  control={<Radio />}
                                  label="Charge the venue"
                                  className={classes.formLabel}
                                />
                                <FormControlLabel
                                  value="PAYMENT"
                                  control={<Radio />}
                                  label="Pay the venue"
                                  className={classes.formLabel}
                                />
                              </RadioGroup>
                            )}
                          </Field>
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <FormLabel className={classes.formLabel}>
                            {values.type === 'CHARGE'
                              ? 'Amount charged to the venue'
                              : 'Amount paid to the venue'}
                          </FormLabel>
                          <FormLabel className={classes.formDescription}>Excluding VAT</FormLabel>
                          <Field
                            component={TextField}
                            name="amount"
                            size="small"
                            value={values.amount}
                            helperText={errors.amount}
                            error={!!errors.amount}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CurrencyPound
                                    className={classes.formDescription}
                                    sx={{ fontSize: 16 }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            required
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <FormControlLabel
                            control={<Checkbox name="vatIncluded" color="primary" />}
                            checked={values.vatIncluded}
                            label="Add VAT 20%"
                            disabled={!values.amount}
                            onChange={(e) => setFieldValue('vatIncluded', e.target.checked)}
                          />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <FormLabel className={classes.formLabel}>Adjustment type</FormLabel>
                          <Field
                            component={TextField}
                            name="schedule"
                            value={values.schedule}
                            size="small"
                            helperText={errors.schedule}
                            error={!!errors.schedule}
                            select
                            required
                          >
                            {SCHEDULE_TYPES.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <FormLabel className={classes.formLabel}>Start date</FormLabel>
                          <FormLabel className={classes.formDescription}>
                            Must be a Monday
                          </FormLabel>
                          {mode === 'EDIT' ? (
                            <Field
                              component={DatePicker}
                              type="date"
                              name="startDate"
                              format="DD/MM/YYYY"
                              shouldDisableDate={(date) => !dateIsMonday(date)}
                              className={classes.datePicker}
                              value={moment(values.startDate)}
                              onChange={(date) => setFieldValue('startDate', moment(date.toDate()))}
                            />
                          ) : (
                            <Field
                              component={DatePicker}
                              type="date"
                              name="startDate"
                              format="DD/MM/YYYY"
                              shouldDisableDate={(date) => !dateIsMonday(date)}
                              className={classes.datePicker}
                              onChange={(date) => setFieldValue('startDate', moment(date.toDate()))}
                            />
                          )}
                        </FormControl>
                        {values.schedule !== 'ONE_OFF' && (
                          <>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                              <FormLabel className={classes.formLabel}>Number of weeks</FormLabel>
                              <Field
                                component={TextField}
                                name="timesDue"
                                type="number"
                                size="small"
                                value={values.timesDue === -1 ? '1' : values.timesDue}
                                helperText={errors.timesDue}
                                error={!!errors.timesDue}
                                disabled={values.timesDue === -1}
                                required
                              />
                            </FormControl>
                            {values.schedule === 'RECURRING' && (
                              <FormControl fullWidth>
                                <FormControlLabel
                                  control={<Checkbox name="repeatsIndefinitely" color="primary" />}
                                  checked={values.timesDue === -1}
                                  label="Repeats indefinitely"
                                  onChange={(e) => {
                                    if (!e.target.checked) {
                                      setFieldValue('timesDue', 1);
                                    } else {
                                      setFieldValue('timesDue', -1);
                                    }
                                  }}
                                />
                              </FormControl>
                            )}
                          </>
                        )}
                      </CardContent>
                    </Form>
                  </>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                {adjustmentCreated && (
                  <Paper sx={{ p: 2, mb: 2 }}>
                    <Chip
                      label="Scheduled"
                      color="primary"
                      size="small"
                      sx={{ backgroundColor: '#DFDFFF', color: '#000' }}
                    />
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      Created {moment(adjustmentCreated.createdAt).format('Do MMM YYYY')}
                    </Typography>
                    <Typography variant="body1">By: {adjustmentCreated.createdBy}</Typography>
                  </Paper>
                )}
                <Paper sx={{ p: 2 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Summary
                  </Typography>
                  <Typography variant="body2" className={classes.formLabel} sx={{ mt: 2 }}>
                    {values.type === 'CHARGE' ? 'Charge this venue:' : 'Pay this venue:'}
                  </Typography>
                  <Typography variant="body1" className={classes.summaryAmount}>
                    {values.amount
                      ? `£${getSummaryAmount({
                          amount: values.amount,
                          applyVat: values.vatIncluded,
                        })} ${values.schedule === 'SPREAD' ? ' each week' : ''}`
                      : '£0.00'}
                  </Typography>
                  {values.timesDue && values.schedule === 'SPREAD' && (
                    <Typography variant="body1" className={classes.formLabel} sx={{ mb: 1 }}>
                      for {values.timesDue} weeks
                    </Typography>
                  )}
                  {values.startDate && (
                    <Typography variant="body1" className={classes.formLabel}>
                      Starting: {formattedStartDate}
                    </Typography>
                  )}
                  {values.schedule !== 'ONE_OFF' && (
                    <Typography variant="body1" className={classes.formLabel}>
                      Ending: {values.timesDue === -1 ? 'Never' : endDate}
                    </Typography>
                  )}
                  <Divider sx={{ my: 2 }} />
                  <FormControl fullWidth>
                    <FormLabel className={classes.formLabel}>Description</FormLabel>
                    <Field component={TextField} multiline name="description" minRows="5" />
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </Formik>
  );
};

export default AdjustmentForm;
