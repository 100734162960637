import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CheckCircleOutline } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import PageHeader from '../../../../components/PageHeader';
import BackArrow from '../../../../components/BackArrow';
import Page from '../../../../components/Page';
import { createAdjustment, deleteAdjustment, patchAdjustment } from '../../../../store/adjustments';
import { useNotifications } from '../../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../../shared/utils/errors';
import AdjustmentForm from '../AdjustmentForm';

const initialValues = {
  option: 'TECH_FEE',
  name: 'Tech fee',
  type: 'CHARGE',
  schedule: 'ONE_OFF',
  startDate: null,
  vatIncluded: false,
  timesDue: 1,
};

const useStyles = makeStyles((theme) => ({
  successBanner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 24,
    backgroundColor: '#87EB80',
    borderRadius: 8,
    padding: 16,
    color: '#000',
  },
  successBannerText: {
    ...theme.customFonts.small,
    marginLeft: 12,
  },
}));

const CreateAdjustment = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const [displaySuccessBanner, setDisplaySuccessBanner] = useState(null);

  const [adjustmentResponse, setAdjustmentResponse] = useState(null);

  const handleSubmit = async (values) => {
    try {
      let adjustmentApiResponse;

      if (!adjustmentResponse) {
        adjustmentApiResponse = await dispatch(createAdjustment(values));
        setDisplaySuccessBanner('Adjustment was created successfully');
      } else {
        adjustmentApiResponse = await dispatch(
          patchAdjustment(adjustmentResponse.adjustmentId, values),
        );
        setDisplaySuccessBanner('Adjustment was updated successfully');
      }
      setAdjustmentResponse(adjustmentApiResponse);
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteAdjustment(adjustmentResponse.adjustmentId));
      showSuccessNotification('Adjustment was deleted successfully');
      history.push('/admin/delivery-adjustments');
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }
  };

  return (
    <>
      <PageHeader>
        <BackArrow to="/admin/delivery-adjustments" text="Adjustments" />
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant="h1">Create new adjustment</Typography>
          {adjustmentResponse && (
            <Button variant="outlined" onClick={() => handleDelete()}>
              Delete
            </Button>
          )}
        </Box>
        {displaySuccessBanner && (
          <Box className={classes.successBanner}>
            <CheckCircleOutline />
            <Typography variant="body1" className={classes.successBannerText}>
              {displaySuccessBanner}
            </Typography>
          </Box>
        )}
      </PageHeader>
      <Page>
        <AdjustmentForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          adjustmentCreated={adjustmentResponse}
        />
      </Page>
    </>
  );
};

export default CreateAdjustment;
