import {
  Box,
  Button,
  Card,
  CardContent,
  Switch,
  Grid,
  Typography,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Buffer } from 'buffer';

import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import Page from '../../components/Page';
import { getAdminState } from '../../store/admin/selectors';
import {
  fetchAdmin,
  updateAdmin,
  forcePasswordReset,
  deleteVenue,
  deleteUser,
} from '../../store/admin';
import { getErrorMessage } from '../../shared/utils/errors';
import createTemporaryPassword from '../../shared/utils/createTemporaryPassword';
import { getVenuesSettingsState, getVenueState } from '../../store/venues/selectors';
import {
  fetchVenue,
  fetchVenues,
  fetchVenueSettings,
  updateVenue,
  updateVenueSettings,
} from '../../store/venues';
import ResetPasswordDialog from '../../components/AdminForm/ResetPasswordDialog';
import PaymentSystemDialog from '../../components/AdminForm/PaymentSystemDialog';
import DeleteVenueDialog from '../../components/AdminForm/DeleteVenueDialog';
import AddNewManagerForm from '../../components/AdminForm/AddNewManagerForm';
import DeleteVenueManagerDialog from '../../components/AdminForm/DeleteVenueManagerDialog';
import ManagersTable from '../../components/ManagersTable';
import AddDebitForm from '../../components/AdminForm/AddDebitForm';
import { createDebit } from '../../store/debits';
import AddDebitDialog from '../../components/AdminForm/AddDebitDialog';
import CustomPaymentForm from '../../components/AdminForm/CustomPaymentForm';
import { sendPayments } from '../../store/payments';
import withVenue from '../../hoc/withVenue';
import ManagerFormDialog from '../Settings/Team/ManagerFormDialog';
import { createManager, deleteManager, fetchManagers } from '../../store/managers';
import useRoles from '../../hooks/useRoles';
import UploadQrCodes from '../../components/AdminForm/UploadQrCodes';
import { uploadBatchQrCodes } from '../../store/qrcodes';
import CustomWarningBanner from '../../components/CustomWarningBanner';
import { fetchBilling } from '../../store/billing';
import { getBillingState } from '../../store/billing/selectors';
import useEndpoint from '../../hooks/useEndpoint';
import { createApicbase, deleteApicbase } from '../../api/venues';

const useStyles = makeStyles(() => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    flexGrow: 1,
  },
  disableButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '10px',
  },
  kioskButtons: {
    justifyContent: 'space-between',
    paddingBottom: '12px',
  },
}));

const Admin = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { isAdmin, isSuperAdmin } = useRoles();
  const [confirmAddDebitDialogOpen, setConfirmAddDebitDialogOpen] = useState(false);
  const [confirmKillDialogOpen, setConfirmKillDialogOpen] = useState(false);
  const [confirmPwdDialogOpen, setConfirmPwdDialogOpen] = useState(false);
  const [confirmAddNewManagerDialogOpen, setConfirmAddNewManagerDialogOpen] = useState(false);
  const [confirmManagerDeleteDialogOpen, setConfirmManagerDeleteDialogOpen] = useState(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [newManagerEmail, setNewManagerEmail] = useState('');
  const [debitAmount, setDebitAmount] = useState(0);
  const [debitReason, setDebitReason] = useState('');
  const [deleteEmail, setDeleteEmail] = useState('');
  const [temporaryPassword, setTemporaryPassword] = useState(createTemporaryPassword());
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { loading, data: admin, error } = useSelector(getAdminState);
  const { loading: venueLoading, data: venue } = useSelector(getVenueState);
  const { accountType, apicbaseId, apicbaseKey } = venue;
  const [venueAccountType, setVenueAccountType] = useState(accountType);
  const [loadVenue, setLoadVenue] = useState(true);
  const [resetEmail, setResetEmail] = useState('');
  const {
    loading: settingsLoading,
    data: venueSettings,
    error: settingsError,
  } = useSelector(getVenuesSettingsState);
  const { data: plan } = useEndpoint(getBillingState, fetchBilling()) || {};

  const getSetting = useCallback(
    (settingName) =>
      venueSettings
        ? venueSettings?.find((setting) => setting.settingName === settingName)?.value
        : '',
    [venueSettings],
  );

  const isDeliverectOn = getSetting('SEND_ORDERS_TO_DELIVERECT');
  const isAutoPrint = getSetting('AUTO_PRINT');
  const isKioskEnabled = getSetting('KIOSK_ENABLED');
  const isSessionsBrandEnabled = getSetting('SESSIONS_BRAND_ENABLED');
  const isAdhocItemsEnabled = getSetting('ADHOC_ITEMS_ENABLED');

  const getKioskSetting = useCallback(
    (kioskSettingName) => {
      const kioskSettingValue = venueSettings?.find(
        (setting) => setting.settingName === 'KIOSK_ENABLED',
      )?.kioskSettings?.[kioskSettingName];

      return kioskSettingValue || false;
    },
    [venueSettings],
  );
  const isDeliveryEnabled = getKioskSetting('isDeliveryOn');
  const isEatinEnabled = getKioskSetting('isEatinOn');

  useEffect(() => {
    if (!venueSettings && !settingsLoading && !settingsError) dispatch(fetchVenueSettings());
  }, [dispatch, venueSettings, settingsLoading, settingsError]);

  useEffect(() => {
    const loadAdmin = async () => {
      if (!admin && !loading && !error) await dispatch(fetchAdmin());
    };
    loadAdmin();
  }, [dispatch, admin, loading, error]);

  useEffect(() => {
    // refetch venue to make sure we have all the data, and not a subset (e.g. missing managers data)
    const loadVenuefn = async () => {
      if (loadVenue && !venue) {
        await dispatch(fetchVenue());
        setLoadVenue(false);
      }
    };
    const { hwStatus } = venue || {};
    if (!hwStatus) loadVenuefn();
  }, [dispatch, loadVenue, venue]);

  useEffect(() => {
    if (confirmPwdDialogOpen || confirmAddNewManagerDialogOpen)
      setTemporaryPassword(createTemporaryPassword());
  }, [confirmAddNewManagerDialogOpen, confirmPwdDialogOpen]);

  const handleCloseSizeDialog = () => {
    setConfirmKillDialogOpen(false);
  };

  const handleCloseAddDebitDialog = () => {
    setConfirmAddDebitDialogOpen(false);
  };

  const submitAddDebit = async () => {
    handleCloseAddDebitDialog();
    try {
      await dispatch(createDebit(debitAmount, debitReason));
      showSuccessNotification('Debit has been created successfully');
      dispatch(fetchAdmin());
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const submitKillCheckout = async () => {
    handleCloseSizeDialog();
    try {
      await dispatch(
        updateAdmin({
          values: { defaultPayment: admin?.defaultPayment === 'STRIPE' ? 'CHECKOUT' : 'STRIPE' },
        }),
      );
      showSuccessNotification('Admin Settings have been updated successfully');
      dispatch(fetchAdmin());
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const submitUpdateOnDeliverect = async () => {
    try {
      await dispatch(
        updateVenueSettings({
          settingName: 'SEND_ORDERS_TO_DELIVERECT',
          settingValue: !isDeliverectOn,
        }),
      );
      showSuccessNotification('SEND_ORDERS_TO_DELIVERECT Setting has been updated successfully');
      dispatch(fetchVenueSettings());
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const handleSubmitMiscItemsToggle = async () => {
    try {
      await dispatch(
        updateVenueSettings({
          settingName: 'ADHOC_ITEMS_ENABLED',
          settingValue: !isAdhocItemsEnabled,
        }),
      );
      showSuccessNotification('ADHOC_ITEMS_ENABLED has been updated successfully');
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const submitUpdateOnAutoPrint = async () => {
    try {
      await dispatch(
        updateVenueSettings({
          settingName: 'AUTO_PRINT',
          settingValue: !isAutoPrint,
        }),
      );
      showSuccessNotification('AUTO_PRINT Setting has been updated successfully');
      dispatch(fetchVenueSettings());
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const submitUpdateKioskConf = async (toggleName) => {
    try {
      let updatePayload;
      let successMessage;

      switch (toggleName) {
        case 'useKiosk':
          updatePayload = {
            settingName: 'KIOSK_ENABLED',
            settingValue: !isKioskEnabled,
          };
          successMessage = 'Kiosk enabled setting has been updated successfully';
          break;
        case 'deliveryOn':
          updatePayload = {
            settingName: 'KIOSK_ENABLED',
            settingValue: isKioskEnabled,
            isDeliveryOn: !isDeliveryEnabled,
          };
          successMessage = 'Delivery on setting has been updated successfully';
          break;
        case 'eatingOn':
          updatePayload = {
            settingName: 'KIOSK_ENABLED',
            settingValue: isKioskEnabled,
            isEatinOn: !isEatinEnabled,
          };
          successMessage = 'Eating on setting has been updated successfully';
          break;
        default:
      }

      await dispatch(updateVenueSettings(updatePayload));

      showSuccessNotification(successMessage);
      dispatch(fetchVenueSettings());
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const submitUpdateSessionsBrandConf = async () => {
    try {
      await dispatch(
        updateVenueSettings({
          settingName: 'SESSIONS_BRAND_ENABLED',
          settingValue: !isSessionsBrandEnabled,
        }),
      );
      showSuccessNotification('SESSIONS_BRAND_ENABLED Setting has been updated successfully');
      dispatch(fetchVenueSettings());
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const handleClosePwdDialog = () => {
    setConfirmPwdDialogOpen(false);
  };

  const handleCloseAddNewManagerDialog = () => {
    setConfirmAddNewManagerDialogOpen(false);
  };

  const submitPwdDialog = async (email, password) => {
    handleClosePwdDialog();
    try {
      await dispatch(forcePasswordReset({ values: { email, temporaryPassword: password } }));
      showSuccessNotification('Password reset successfully');
      dispatch(fetchAdmin());
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const submitAddNewManagerDialog = async ({ email, role, name, countryCode, phone }) => {
    handleCloseAddNewManagerDialog();
    try {
      await dispatch(createManager({ email, role, name, countryCode, phone }));
      setNewManagerEmail('');
      showSuccessNotification('New manager added successfully');
      dispatch(fetchManagers());
      dispatch(fetchVenue());
    } catch (newError) {
      showErrorNotification('New manager could not be created');
    }
  };

  const handleCloseDeleteDialog = () => {
    setConfirmDeleteDialogOpen(false);
  };

  const handleCloseManagerDeleteDialog = () => {
    setConfirmManagerDeleteDialogOpen(false);
  };

  const submitDeleteDialog = async (venueId) => {
    handleCloseDeleteDialog();
    try {
      await dispatch(deleteVenue(venueId));
      showSuccessNotification('Venue has been deleted');
      dispatch(fetchVenues());
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const submitMangerDeleteDialog = async (managerEmail) => {
    handleCloseManagerDeleteDialog();
    try {
      if (isSuperAdmin()) {
        await dispatch(deleteUser(managerEmail));
      } else {
        await dispatch(deleteManager(managerEmail));
      }
      showSuccessNotification('Manager has been deleted');
      dispatch(fetchVenue());
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const { managers, name, venueId } = venue || {};
  const managersSectionTitle = `${name} Manager${managers?.length > 1 ? 's' : ''}:`;

  const handleCustomPayment = async (values) => {
    try {
      await dispatch(sendPayments(values));
      showSuccessNotification('Payment sent successfully');
      dispatch(fetchVenue());
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
    // POST to API
  };

  const uploadQrCodes = async ({ target }) => {
    try {
      const spreadsheet = target.files[0];
      const csvContents = await spreadsheet.text();
      const base64Csv = Buffer.from(csvContents).toString('base64');
      await dispatch(uploadBatchQrCodes(base64Csv));
      showSuccessNotification('Upload sent successfully');
    } catch (uploadError) {
      showErrorNotification(getErrorMessage(uploadError));
    }
  };

  const handleReactivate = async () => {
    await dispatch(updateVenue({ accountStatus: 'ACTIVE' }));
    dispatch(fetchVenue());
  };

  const ACCOUNT_TYPES = ['STANDARD', 'PRO', 'DELIVERY_ONLY'];

  const handleUpdateAccountTypeSelect = (event) => {
    setVenueAccountType(event.target.value);
  };

  const handleUpdateAccountType = async () => {
    await dispatch(updateVenue({ accountType: venueAccountType }));
    dispatch(fetchVenue());
  };

  const handleApicbase = async () => {
    try {
      if (apicbaseId) {
        await deleteApicbase();
        showSuccessNotification('Apicbase deleted successfully');
      } else {
        await createApicbase();
        showSuccessNotification('Apicbase created successfully');
      }

      dispatch(fetchVenue());
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  return (
    <>
      <Page
        loading={!isAdmin() || loading || venueLoading || settingsLoading}
        error={error || settingsError}
      >
        <br />
        <Card>
          <CardContent>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item className={classes.formElement}>
                <Typography className={classes.title} variant="h2" component="h1">
                  Venue fees
                </Typography>
              </Grid>
              <Grid item className={classes.formElement}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push('/admin/venue-fees')}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item className={classes.formElement}>
                <Typography className={classes.title} variant="h2" component="h1">
                  Delivery payout adjustments
                </Typography>
              </Grid>
              <Grid item className={classes.formElement}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push('/admin/delivery-adjustments')}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br />
        <br />
        <Card>
          <CardContent>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item className={classes.formElement}>
                <Typography className={classes.title} variant="h2" component="h1">
                  Stripe customer details
                </Typography>
              </Grid>
              <Grid item className={classes.formElement}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push('/admin/plan-settings/customer')}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
            {!plan?.customer?.enabled && (
              <>
                <br />
                <CustomWarningBanner
                  title="This stripe customer is currently inactive"
                  titleFont="smallBold"
                  titleColor="greyDarker"
                  iconColor="#FFB800"
                  backgroundColor="warningYellow"
                />
              </>
            )}
          </CardContent>
        </Card>
        <br />
        <header>
          <Box className={classes.heading}>
            <Typography className={classes.title} variant="h2" component="h1">
              {managersSectionTitle}
            </Typography>
            <aside>
              <AddNewManagerForm
                email={newManagerEmail}
                setEmail={setNewManagerEmail}
                setConfirmDialogOpen={setConfirmAddNewManagerDialogOpen}
              />
            </aside>
          </Box>
        </header>
        <br />
        <Card>
          {venue && (
            <ManagersTable
              managers={managers}
              adminMode
              setResetEmail={setResetEmail}
              setConfirmPwdDialogOpen={setConfirmPwdDialogOpen}
              setDeleteEmail={setDeleteEmail}
              setConfirmManagerDeleteDialogOpen={setConfirmManagerDeleteDialogOpen}
            />
          )}
        </Card>
        <br />
        <header>
          <Box className={classes.heading}>
            <Typography className={classes.title} variant="h2" component="h1">
              {`${name} Admin`}
            </Typography>
            <Box display="flex" style={{ gap: '1rem', paddingTop: '1.5rem' }}>
              <Box display="flex" justifyContent="flex-end" style={{ gap: '1rem' }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={venue?.accountStatus === 'ACTIVE'}
                  startIcon={<WarningIcon />}
                  onClick={handleReactivate}
                >
                  Reactivate Venue
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<WarningIcon />}
                  onClick={() => setConfirmDeleteDialogOpen(true)}
                >
                  Delete Venue
                </Button>
              </Box>
            </Box>
          </Box>
          <Card style={{ marginTop: '1rem' }}>
            <CardContent>
              <InputLabel id="account-type-change-label">Account Type</InputLabel>
              <Select
                value={venueAccountType}
                onChange={handleUpdateAccountTypeSelect}
                variant="outlined"
                labelId="account-type-change-label"
                fullWidth
                style={{ margin: '1rem 0' }}
                disabled={!isAdmin()}
              >
                {!ACCOUNT_TYPES.includes(accountType) && (
                  <MenuItem key={accountType} value={accountType} disabled>
                    {accountType}
                  </MenuItem>
                )}
                {ACCOUNT_TYPES.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              <Button
                variant="contained"
                color="primary"
                disabled={venueAccountType === accountType || !isAdmin()}
                onClick={handleUpdateAccountType}
              >
                Save
              </Button>
            </CardContent>
          </Card>
        </header>
        <br />
        <header>
          <Box className={classes.heading}>
            <Typography className={classes.title} variant="h2" component="h1">
              {`One-off charge to ${name}`}
            </Typography>
          </Box>
        </header>
        <br />
        <Card>
          <CardContent>
            <AddDebitForm
              amount={debitAmount}
              reason={debitReason}
              setAmount={setDebitAmount}
              setReason={setDebitReason}
              setConfirmDialogOpen={setConfirmAddDebitDialogOpen}
            />
          </CardContent>
        </Card>
        <br />
        {isSuperAdmin() && (
          <>
            <header>
              <Box className={classes.heading}>
                <Typography className={classes.title} variant="h2" component="h1">
                  {`One-off payment to ${name}`}
                </Typography>
              </Box>
            </header>
            <Card>
              <CardContent>
                <CustomPaymentForm onSubmit={handleCustomPayment} />
              </CardContent>
            </Card>
          </>
        )}
        {isAdmin() && (
          <>
            <header>
              <Box className={classes.heading}>
                <Typography className={classes.title} variant="h2" component="h1">
                  Deliverect
                </Typography>
              </Box>
            </header>
            <br />
            <Card>
              <CardContent>
                <Grid container direction="row" justifyContent="space-between">
                  {admin?.defaultPayment && (
                    <Grid item className={classes.formElement}>
                      Use Deliverect: <strong>{isDeliverectOn?.toString()}</strong>
                    </Grid>
                  )}
                  <Grid item className={classes.formElement}>
                    <Switch
                      checked={isDeliverectOn === true}
                      onChange={() => submitUpdateOnDeliverect()}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <br />
            <header>
              <Box className={classes.heading}>
                <Typography className={classes.title} variant="h2" component="h1">
                  Auto print (Cloud Printer)
                </Typography>
              </Box>
            </header>
            <br />
            <Card>
              <CardContent>
                <Grid container direction="row" justifyContent="space-between">
                  {admin?.defaultPayment && (
                    <Grid item className={classes.formElement}>
                      Use Auto print (Cloud Printer): <strong>{isAutoPrint?.toString()}</strong>
                    </Grid>
                  )}
                  <Grid item className={classes.formElement}>
                    <Switch
                      checked={isAutoPrint === true}
                      onChange={() => submitUpdateOnAutoPrint()}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <br />
            <header>
              <Box className={classes.heading}>
                <Typography className={classes.title} variant="h2" component="h1">
                  Kiosk settings
                </Typography>
              </Box>
            </header>
            <br />
            <Card>
              <CardContent>
                <Grid container direction="row" className={classes.kioskButtons}>
                  <Grid item className={classes.formElement}>
                    Kiosk enabled: <strong>{isKioskEnabled?.toString()}</strong>
                  </Grid>
                  <Grid item className={classes.formElement}>
                    <Switch
                      checked={isKioskEnabled === true}
                      onChange={() => submitUpdateKioskConf('useKiosk')}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" className={classes.kioskButtons}>
                  <Grid item className={classes.formElement}>
                    Kiosk enabled for eat-in: <strong>{isEatinEnabled?.toString()}</strong>
                  </Grid>
                  <Grid item className={classes.formElement}>
                    <Switch
                      checked={isEatinEnabled === true}
                      onChange={() => submitUpdateKioskConf('eatingOn')}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item className={classes.formElement}>
                    Kiosk enabled for takeaway: <strong>{isDeliveryEnabled?.toString()}</strong>
                  </Grid>
                  <Grid item className={classes.formElement}>
                    <Switch
                      checked={isDeliveryEnabled === true}
                      onChange={() => submitUpdateKioskConf('deliveryOn')}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <br />
            <header>
              <Box className={classes.heading}>
                <Typography className={classes.title} variant="h2" component="h1">
                  Sessions Brands
                </Typography>
              </Box>
            </header>
            <br />
            <Card>
              <CardContent>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item className={classes.formElement}>
                    Venue is allowed to add Sessions Branded items to their in-house menus
                  </Grid>

                  <Grid item className={classes.formElement}>
                    <Switch
                      checked={isSessionsBrandEnabled === true}
                      onChange={() => submitUpdateSessionsBrandConf()}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <br />
            <header>
              <Box className={classes.heading}>
                <Typography className={classes.title} variant="h2" component="h1">
                  Upload QR codes
                </Typography>
              </Box>
            </header>
            <br />
            <Card>
              <CardContent>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item className={classes.formElement}>
                    Upload a batch of Qr Codes
                  </Grid>
                  <Grid item className={classes.formElement}>
                    <UploadQrCodes handleFileInput={uploadQrCodes} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <br />
            <header>
              <Box className={classes.heading}>
                <Typography className={classes.title} variant="h2" component="h1">
                  Misc items
                </Typography>
              </Box>
            </header>
            <br />
            <Card>
              <CardContent>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item className={classes.formElement}>
                    Venue is allowed to create and sell misc items on their EPOS
                  </Grid>
                  <Grid item className={classes.formElement}>
                    <Switch
                      checked={isAdhocItemsEnabled === true}
                      onChange={() => handleSubmitMiscItemsToggle()}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {isAdmin() && (
              <>
                <br />
                <header>
                  <Box className={classes.heading}>
                    <Typography className={classes.title} variant="h2" component="h1">
                      Apicbase
                    </Typography>
                  </Box>
                </header>
                <br />
                <Card>
                  <CardContent>
                    <Grid
                      container
                      direction={apicbaseId ? 'column' : 'row'}
                      justifyContent="space-between"
                    >
                      <Grid item className={classes.formElement}>
                        {apicbaseId ? (
                          <span>
                            Apicbase Key: <strong>{apicbaseKey}</strong>
                          </span>
                        ) : (
                          'Enable Apicbase'
                        )}
                      </Grid>
                      <Grid
                        item
                        className={apicbaseId ? classes.disableButton : classes.formElement}
                      >
                        <Button variant="contained" color="primary" onClick={handleApicbase}>
                          {apicbaseId ? 'Disable' : 'Enable'}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </>
            )}

            <br />
            <br />
            <br />
            <br />
          </>
        )}
      </Page>
      <AddDebitDialog
        handleCloseDialog={handleCloseAddDebitDialog}
        submitDialog={submitAddDebit}
        handleDialogOpen={confirmAddDebitDialogOpen}
        amount={debitAmount}
        reason={debitReason}
      />
      <PaymentSystemDialog
        handleCloseDialog={handleCloseSizeDialog}
        submitDialog={submitKillCheckout}
        handleDialogOpen={confirmKillDialogOpen}
        adminData={admin}
      />
      <ManagerFormDialog
        action="create"
        email={newManagerEmail}
        submitDialog={submitAddNewManagerDialog}
        handleDialogOpen={confirmAddNewManagerDialogOpen}
        handleCloseDialog={handleCloseAddNewManagerDialog}
      />
      <ResetPasswordDialog
        handleCloseDialog={handleClosePwdDialog}
        submitDialog={submitPwdDialog}
        handleDialogOpen={confirmPwdDialogOpen}
        email={resetEmail}
        temporaryPassword={temporaryPassword}
      />
      <DeleteVenueDialog
        handleCloseDialog={handleCloseDeleteDialog}
        submitDialog={submitDeleteDialog}
        handleDialogOpen={confirmDeleteDialogOpen}
        venueId={venueId || ''}
        venueName={name || ''}
      />
      <DeleteVenueManagerDialog
        handleCloseDialog={handleCloseManagerDeleteDialog}
        submitDialog={submitMangerDeleteDialog}
        handleDialogOpen={confirmManagerDeleteDialogOpen}
        managerEmail={deleteEmail || ''}
      />
    </>
  );
};
export default withVenue(Admin);
